<template>
  <v-app>
    <v-container
      class="fill-height pa-0"
      fluid
    >
      <router-view />
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'Simple'
}
</script>

<style lang="scss" scoped>
.container {
  background-color: $primary-black;
}

@media screen and (max-width: $mobile) {
  .container {
    background-color: $primary-darkgrey;
  }
}
</style>
